<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-[900]" @close="handleClickOutside">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                :class="['pointer-events-auto w-screen', size]"
                v-bind="$attrs"
              >
                <div
                  class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
                >
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle
                        class="text-base font-semibold leading-6 text-gray-900 line-clamp-2"
                        >{{ title }}</DialogTitle
                      >
                      <div class="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          class="flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          v-if="showCloseIcon"
                          @click="handleClickClosePanel"
                        >
                          <span class="sr-only">Close panel</span>
                          <XCircleIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                        <button
                          class="rounded border shadow border-untitled-gray-400 px-2 py-1 flex justify-center items-center text-sm font-semibold text-untitled-gray-900 hover:bg-untitled-gray-100 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-untitled-gray-100"
                          v-else
                          @click="handleClickClosePanel"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <slot></slot>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { defineProps, computed, defineEmits } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XCircleIcon } from "@heroicons/vue/outline";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  open: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: "medium",
  },
  showCloseIcon: {
    type: Boolean,
    default: true,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabledCloseOutside: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["closePanel", "update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
const open = computed(() => props.open);
const title = computed(() => props.title);
const showCloseIcon = computed(() => props.showCloseIcon);
const disabledCloseOutside = computed(() => props.disabledCloseOutside);
const size = computed(() => {
  if (props.size === "small") {
    return "max-w-sm";
  }

  return "max-w-2xl";
});

const handleClickClosePanel = () => {
  value.value = false;
  emit("closePanel");
};

const handleClickOutside = () => {
  if (disabledCloseOutside.value) {
    return;
  }
  value.value = false;
  emit("closePanel");
};
</script>
