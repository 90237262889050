<template>
  <div
    v-if="event"
    @click="handleClickViewEvent"
    class="w-full flex justify-center cursor-pointer items-center gap-1 py-2 border-b border-untitled-gray-200 bg-untitled-gray-200 animate-pulse"
  >
    <div class="text-sm text-untitled-gray-600 font-semibold">{{ event.name }}</div>
    <span class="mx-1">|</span>
    <CalendarIcon class="text-untitled-gray-600 w-6" />
    <div class="text-sm text-untitled-gray-600 font-semibold">
      {{ dateFormat(event.start_at)}}
    </div>
  </div>
</template>

<script setup>
import { CalendarIcon } from "@heroicons/vue/solid";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { dateFormat } from "@/helpers/date_formatter";
import { http } from "@/composable/httpService";

const router = useRouter();

const event = ref(null);

const handleClickViewEvent = () => {
  router.push({ name: "event-detail-page", params: { id: event.value.id } });
};

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getUpcomingEvent = async () => {
  const { data } = await http().get(`/v3/events/featured-event?timezone=${userTimeZone}`);
  event.value = data.data;
};

onMounted(() => {
  getUpcomingEvent();
});
</script>

<style lang="scss" scoped></style>
