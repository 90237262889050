import dayjs from "dayjs";

export const dateFormat = (date, format = "ddd, MMM D, YYYY h:mm A") => {
  if (date === "No date" || !date) {
    return " No Assigned date";
  }
  return dayjs(date).format(format);
};

export const formatDateForCalendar = (date, format = "YYYY-MM-DD") => {
  if (date === "No date" || !date) {
    return " No Assigned date";
  }
  return dayjs(date).format(format);
};

export const formatTimeForCalendar = (date, format = "HH:mm") => {
  if (date === "No date" || !date) {
    return " No Assigned date";
  }
  return dayjs(date).format(format);
};
