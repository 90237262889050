import { registerModules } from "./registerModules";

import LmsSettings from "./modules/LmsSettings";
import CustomPlaylist from "./modules/CustomPlaylist";
import Events from "./modules/Events";

registerModules({
  lmsSettings: LmsSettings,
  customPlaylist: CustomPlaylist,
  Events,
});
