import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import MyNotes from "../views/MyNotes.vue";
import { getRoles } from "@/composable/users/getRoles.js";
import { useAuthStore } from "@/stores";

const routes = [
  {
    path: "/pre-login",
    name: "pre.login",
    meta: {
      auth: false,
      title: "Pre Login",
    },
    component: () => import("../views/PreLogin.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: {
      auth: true,
      title: "Home",
    },
    component: HomePage,
  },
  {
    path: "/my-comments",
    name: "mynotes",
    meta: {
      auth: true,
      title: "My Comments",
    },
    component: MyNotes,
  },
  {
    path: "/resource-manager",
    name: "filemanager",
    component: () => import("../views/FileManagerPage.vue"),
    props: true,
    meta: {
      title: "Resource Manager",
      auth: true,
    },
    children: [
      {
        path: ":id",
        name: "Resource Manager Folder",
        component: () => import("../views/FileManagerPage.vue"),
        meta: {
          title: "Resource Manager Folder",
          type: "fileManagerFolder",
        },
      },
    ],
  },
  {
    path: "/reports/:tab?",
    name: "reports",
    props: true,
    meta: {
      auth: true,
      title: "Reports",
    },
    component: () => import("../views/ReportPage.vue"),
  },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    props: false,
    meta: {
      auth: true,
      title: "Leaderboard",
    },
    component: () => import("../views/LeaderBoard.vue"),
  },
  {
    path: "/login",
    name: "login",
    props: false,
    component: () => import("../views/AuthPage.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    props: false,
    component: () => import("../views/PasswordForgot.vue"),
  },
  {
    path: "/password-reset",
    name: "password-reset",
    props: false,
    component: () => import("../views/PasswordReset.vue"),
  },

  {
    path: "/create/playlist",
    name: "create-playlist",
    props: false,
    meta: {
      auth: true,
      title: "Create Playlist",
    },
    component: () => import("../views/CreatePlaylistPage.vue"),
  },
  {
    path: "/edit/playlist/:id",
    name: "edit-playlist",
    props: false,
    meta: {
      auth: true,
      title: "Edit Playlist",
    },
    component: () => import("../views/EditPlaylistPage.vue"),
  },
  {
    path: "/units/:id/:playlistId?",
    name: "units",
    props: false,
    meta: {
      auth: true,
      title: "Unit",
    },
    component: () => import("../views/UnitPage.vue"),
  },
  {
    path: "/client/lms/unit/:id",
    name: "unitsRedirect",
    redirect: (to) => {
      return { name: "units", params: { id: to.params.id } };
    },
  },
  {
    path: "/edit/unit/:id",
    name: "edit-unit",
    props: false,
    component: () => import("../views/EditUnitPage.vue"),
    meta: {
      title: "Edit Unit",
    },
  },
  {
    path: "/edit/unit/:id/course/:courseId?",
    name: "edit-unit-course",
    props: false,
    component: () => import("../views/EditUnitPage.vue"),
  },
  {
    path: "/reminders",
    name: "reminders",
    meta: {
      auth: true,
      title: "Reminders",
    },
    component: () => import("../views/ReminderPage.vue"),
  },
  {
    path: "/search",
    name: "search",
    meta: {
      auth: true,
      title: "Search",
    },
    component: () => import("../views/SearchPage.vue"),
  },
  // {
  //   path: "/events",
  //   name: "events",
  //   meta: {
  //     auth: true,
  //     title: "Events",
  //   },
  //   component: () => import("../views/EventsPage.vue"),
  // },
  {
    path: "/my-videos",
    name: "my-videos",
    props: false,
    meta: {
      auth: true,
      title: "My Videos",
    },
    component: () => import("../views/MyVideosPage.vue"),
  },
  {
    path: "/",
    name: "My Profile",
    meta: {
      title: "My Profile",
    },
  },
  {
    path: "/",
    name: "Preference",
    meta: {
      title: "My Preference",
    },
  },
  {
    path: "/active-role-plays",
    name: "active-role-plays",
    props: false,
    meta: {
      auth: true,
      title: "Active Role Plays",
    },
    component: () => import("../views/ActiveRolePlays.vue"),
  },
  {
    path: "/my-active-role-plays",
    name: "my-active-role-plays",
    props: false,
    meta: {
      auth: true,
      title: "My Active Role Plays",
    },
    component: () => import("../views/MyActiveRolePlays.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/LogoutPage.vue"),
  },
  {
    path: "/*",
    name: "PageNotFound",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/manage/courses",
    name: "courses",
    props: false,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: "products",
        component: () => import("../views/Courses/ManageCourses.vue"),
        meta: {
          title: "Courses",
          type: "courses",
        },
      },
      {
        path: "create/:curriculum_id?",
        name: "courses.create",
        component: () => import("../views/Courses/CreateCoursePage.vue"),
        meta: {
          title: "Create Course",
        },
      },
      {
        path: "edit/:id/",
        name: "courses.edit",
        component: () => import("../views/Courses/EditCoursePage.vue"),
        meta: {
          title: "Edit Courses",
        },
      },
    ],
  },
  {
    path: "/manage/curriculums",
    name: "ManageCurriculums",
    component: () => import("../views/ManageCurriculums.vue"),
    meta: {
      title: "Manage Curriculums",
    },
  },
  {
    path: "/usage-report",
    name: "LMS Statistics",
    component: () => import("../views/LmsStatisticPage.vue"),
    meta: {
      title: "Usage Report",
    },
  },
  {
    path: "/:curriculum_id/manage/courses",
    name: "ManageCurriculumCourses",
    props: true,
    component: () => import("../views/Courses/ManageCourses.vue"),
    meta: {
      title: "Manage Courses",
    },
  },
  {
    path: "/groups",
    props: false,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "",
        name: "groups",
        component: () => import("../views/groups/GroupsPage.vue"),
        meta: {
          title: "Groups",
        },
      },
      {
        path: ":id",
        name: "group",
        component: () => import("../views/groups/GroupPage.vue"),
        meta: {
          title: "Group Details",
        },
      },
      {
        path: ":id/assignment",
        name: "group-assignment",
        component: () => import("../views/groups/GroupPage.vue"),
        meta: {
          title: "Group Details",
        },
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(),
  base: ["staging", "production"].includes(process.env.NODE_ENV)
    ? "/"
    : process.env.BASE_URL,
  linkActiveClass: "active",
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  /**
   * Central Redirections
   */
  if (to.name === "My Profile") {
    window.open("/central/profile");
  }

  if (to.name === "Preference") {
    window.open("/central/preference");
  }

  if (to.name === "Manage Users") {
    window.open("/central/manage-users");
  }

  if (to.name === "Mystery Shops") {
    window.open("/redirect/shops");
  }
  if (to.name === "Resource Manager") {
    window.open("/central/file-manager");
  }

  /* Restricted Pages */
  const roles = getRoles();

  if (
    !roles.isSuperAdmin.value &&
    (to.name === "ManageCurriculumCourses" || to.name === "ManageCurriculums")
  ) {
    router.push({
      name: "PageNotFound",
    });
  }
  if (
    !(roles.isSuperAdmin.value || roles.isAccountManager) &&
    to.name === "LMS Statistics"
  ) {
    router.push({
      name: "PageNotFound",
    });
  }

  if (
    !(roles.isSuperAdmin.value || roles.isAccountManager.value) &&
    ["ManageCourses", "courses.create", "courses.edit"].includes(to.name)
  ) {
    router.push({
      name: "PageNotFound",
    });
  }

  if (useAuthStore().isSingleUser && to.name === "events") {
    router.push({ name: "PageNotFound" });
  }

  if (
    !roles.isSuperAdmin.value &&
    !roles.isAccountManager.value &&
    !roles.isSpecificManager.value &&
    !roles.isUser.value &&
    to.name !== "login" &&
    to.name !== "pre.login" &&
    to.fullPath !== "/" // check if not already on login page
  ) {
    next({ path: "/login", query: { redirect: to.fullPath } });
  }

  if (!to.query.redirect && to.matched.length === 0) {
    // Redirect to PageNotFound if route not found and no redirect query parameter
    router.push({
      name: "PageNotFound",
    });
  }

  if (to.name == "login" && to.redirectedFrom) {
    router.push({
      name: "login",
      query: {
        nextUrl: to.redirectedFrom.fullPath,
      },
    });
  }

  next();
});

router.afterEach(() => {
  var favicon =
    "https://webinarinc-central.s3.us-west-1.amazonaws.com/public/company_logo/revdojo_favicon.ico";
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = favicon;
});
