<script setup>
import { ref, inject } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import LoadingState from "@/components/partials/LoadingState.vue";
import { SearchIcon, ChevronDoubleRightIcon } from "@heroicons/vue/outline";
import { useRouter } from "vue-router";

import { debounce as _debounce } from "lodash-es";
import Bugsnag from "@bugsnag/js";

const axios = inject("axios");
const router = useRouter();

const isOpen = ref(false);
const search = ref(null);
const isLoading = ref(false);
const isSearching = ref(false);
const units = ref([]);
const modules = ref([]);
const sub_modules = ref([]);
const users = ref([]);
const playlists = ref([]);
const groups = ref([])

const closeModal = () => {
  isOpen.value = false;
};
const openModal = () => {
  isOpen.value = true;
};

const globalSearch = _debounce(() => {
  isLoading.value = true;
  isSearching.value = true;
  axios
    .get("/api/search", {
      params: {
        search_query: search.value,
        filter: "all",
      },
    })
    .then(({ data }) => {
      units.value = data.results.units;
      modules.value = data.results.modules;
      sub_modules.value = data.results.sub_modules;
      users.value = data.results.users;
      playlists.value = data.results.playlists;
      groups.value = data.results.groups;
      isLoading.value = false;
    })
    .catch((error) => {
      isLoading.value = false;
      Bugsnag.notify(error);
    });
}, 1000);

const redirectToSearch = () => {
  globalSearch.cancel();
  router.push({
    name: "search",
    query: {
      search: search.value,
    },
  });

  closeModal();
};
</script>
<template>
  <div>
    <div>
      <SearchIcon
        class="h-6 w-6 text-untitled-gray-400 hover:cursor-pointer"
        @click="openModal"
      />
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal" class="relative z-[9999999]">
        <TransitionChild
          as="template"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black bg-opacity-25" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center"
          >
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <DialogPanel
                class="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle transition-all max-h-[500px] bg-transparent"
              >
                <div class="mt-3 flex justify-center items-center relative">
                  <input
                    v-model="search"
                    @keypress="globalSearch"
                    @keypress.enter="redirectToSearch"
                    class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 text-xl"
                    type="text"
                    placeholder="Start typing to search..."
                  />
                  <span
                    v-if="search?.length"
                    class="absolute right-0 mr-2 text-untitled-gray-500 hover:cursor-pointer"
                    @click="redirectToSearch"
                    >Enter</span
                  >
                </div>
                <div v-if="isLoading" class="mt-3 p-10 bg-white text-center">
                  <LoadingState />
                </div>
                <div v-else>
                  <div
                    class="mt-3 px-[10px] max-h-[300px] overflow-y-auto bg-white"
                    v-if="isSearching && !isLoading && search?.length"
                  >
                    <div class="mt-3 pt-3 pb-3">
                      <h3 class="text-lg text-untitled-gray-500">Videos</h3>
                      <ul>
                        <li
                          class="mt-2 text-sm border-b-2 border-b-untitled-gray-200 hover:cursor-pointer hover:bg-slate-200 p-1"
                          v-for="u in units?.slice(0, 5)"
                          :key="u.id"
                        >
                          <a
                            class="flex flex-row items-center text-untitled-gray-500 justify-between w-100"
                            :href="`/units/${u.id}`"
                          >
                            <small>{{ u.name }}</small>
                            <ChevronDoubleRightIcon
                              class="h-4 w-4 text-untitled-gray-400 hover:cursor-pointer"
                            />
                          </a>
                        </li>
                        <li v-if="!units?.length">No unit(s) found</li>
                        <li
                          v-if="units?.length > 5"
                          class="mt-3 font-bold text-sm"
                        >
                          <small>Press enter for more results</small>
                        </li>
                      </ul>
                    </div>
                    <div class="mt-1 border-t-gray-500 pt-3 pb-3">
                      <h3 class="text-lg text-untitled-gray-500">Course</h3>
                      <ul>
                        <li
                          class="mt-2 text-sm border-b-2 border-b-untitled-gray-200 hover:cursor-pointer hover:bg-slate-200 p-1"
                          v-for="m in modules?.slice(0, 5)"
                          :key="m.id"
                        >
                          <a
                            class="flex flex-row items-center text-untitled-gray-500 justify-between w-100"
                            :href="`/units/${m.unit_display.id}`"
                          >
                            <small>{{ m.name }}</small>
                            <ChevronDoubleRightIcon
                              class="h-4 w-4 text-untitled-gray-400 hover:cursor-pointer"
                            />
                          </a>
                        </li>
                        <li v-if="!modules?.length">No course(s) found</li>
                        <li
                          v-if="modules?.length > 5"
                          class="mt-3 font-bold text-sm"
                        >
                          <small>Press enter for more results</small>
                        </li>
                      </ul>
                    </div>
                    <div class="mt-1 border-t-gray-500 pt-3 pb-3">
                      <h3 class="text-lg text-untitled-gray-500">Modules</h3>
                      <ul>
                        <li
                          class="mt-2 text-sm border-b-2 border-b-untitled-gray-200 hover:cursor-pointer hover:bg-slate-200 p-1"
                          v-for="m in sub_modules?.slice(0, 5)"
                          :key="m.id"
                        >
                          <a
                            class="flex flex-row items-center text-untitled-gray-500 justify-between w-100"
                            :href="`/units/${
                              m.first_unit ? m.first_unit.id : m.unit_display?.id
                            }`"
                          >
                            <small>{{ m.name }}</small>
                            <ChevronDoubleRightIcon
                              class="h-4 w-4 text-untitled-gray-400 hover:cursor-pointer"
                            />
                          </a>
                        </li>
                        <li v-if="!sub_modules?.length">No module(s) found</li>
                        <li
                          v-if="sub_modules?.length > 5"
                          class="mt-3 font-bold text-sm"
                        >
                          <small>Press enter for more results</small>
                        </li>
                      </ul>
                    </div>
                    <div class="mt-1 border-t-gray-500 pt-3 pb-3">
                      <h3 class="text-lg text-untitled-gray-500">Playlists</h3>
                      <ul>
                        <li
                          class="mt-2 text-sm border-b-2 border-b-untitled-gray-200 hover:cursor-pointer hover:bg-slate-200 p-1"
                          v-for="p in playlists?.slice(0, 5)"
                          :key="p.id"
                        >
                          <a
                            class="flex flex-row items-center text-untitled-gray-500 justify-between w-100"
                            :href="`/units/${
                              p.first_unit ? p.first_unit.id : p.unit_display?.id
                            }/${p.id}`"
                          >
                            <small>{{ p.name }}</small>
                            <ChevronDoubleRightIcon
                              class="h-4 w-4 text-untitled-gray-400 hover:cursor-pointer"
                            />
                          </a>
                        </li>
                        <li v-if="!playlists?.length">No playlist(s) found</li>
                        <li
                          v-if="playlists?.length > 5"
                          class="mt-3 font-bold text-sm"
                        >
                          <small>Press enter for more results</small>
                        </li>
                      </ul>
                    </div>
                    <div class="mt-1 border-t-gray-500 pt-3 pb-3">
                      <h3 class="text-lg text-untitled-gray-500">Users</h3>
                      <ul>
                        <li
                          class="mt-2 text-sm border-b-2 border-b-untitled-gray-200 hover:cursor-pointer hover:bg-slate-200 p-1"
                          v-for="u in users?.slice(0, 5)"
                          :key="u.id"
                        >
                          <a
                            class="flex flex-row items-center text-untitled-gray-500 justify-between w-100"
                            :href="`/reports?tab=course-overview&userId=${u?.id}`"
                          >
                            <small>{{ u.name }}</small>
                            <ChevronDoubleRightIcon
                              class="h-4 w-4 text-untitled-gray-400 hover:cursor-pointer"
                            />
                          </a>
                        </li>
                        <li v-if="!users?.length">No user(s) found</li>
                        <li
                          v-if="users?.length > 5"
                          class="mt-3 font-bold text-sm"
                        >
                          <small>Press enter for more results</small>
                        </li>
                      </ul>
                    </div>
                    <div class="mt-1 border-t-gray-500 pt-3 pb-3">
                      <h3 class="text-lg text-untitled-gray-500">Groups</h3>
                      <ul>
                        <li
                          class="mt-2 text-sm border-b-2 border-b-untitled-gray-200 hover:cursor-pointer hover:bg-slate-200 p-1"
                          v-for="g in groups?.slice(0, 5)"
                          :key="g.id"
                        >
                          <a
                            class="flex flex-row items-center text-untitled-gray-500 justify-between w-100"
                            :href="`/groups/${g?.id}`"
                          >
                            <small>{{ g.name }}</small>
                            <ChevronDoubleRightIcon
                              class="h-4 w-4 text-untitled-gray-400 hover:cursor-pointer"
                            />
                          </a>
                        </li>
                        <li v-if="!groups?.length">No group(s) found</li>
                        <li
                          v-if="groups?.length > 5"
                          class="mt-3 font-bold text-sm"
                        >
                          <small>Press enter for more results</small>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
